import React, { useState } from 'react';
import { TextField, Box, Grid, IconButton, Typography, Button, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { Add, Remove, ArrowBack } from '@mui/icons-material';
import { useSprings, animated } from '@react-spring/web';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { publicInstance } from '../axiosInstance'; 

const AddShipment = () => {
  const theme = useTheme();
  const isLightTheme = theme.name === 'light';
  const navigate = useNavigate();

  const initialValues = {
    ORANGE: '',
    PLAY: '',
    PLUS: '',
    p5: '',
    p8: '',
    receiver: '',
    date: null,
    comments: '',
  };

  const [values, setValues] = useState(initialValues);

  const [showComments, setShowComments] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  const [errors, setErrors] = useState({
    receiver: false,
    date: false,
    devices: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (['ORANGE', 'PLAY', 'PLUS', 'p5', 'p8'].includes(name)) {
      if (/^\d{0,4}$/.test(value)) {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    setValues({
      ...values,
      date: newDate,
    });
  };

  const incrementDecrementContinuous = (name: string, operation: 'increment' | 'decrement') => {
    const updateValue = () => {
      setValues((prevValues) => {
        const newValue = operation === 'increment'
          ? (parseInt(prevValues[name] || '0') + 1).toString()
          : Math.max(0, parseInt(prevValues[name] || '0') - 1).toString();
        return { ...prevValues, [name]: newValue };
      });
    };
    updateValue();
    const intervalId = setInterval(updateValue, 100);

    return () => clearInterval(intervalId);
  };

  const renderBlocks = (count: number, color: string, isP5 = false, isP8 = false) => {
    const blockWidth = isP5 || isP8 ? 130 : 60;
    const gridTemplate = isP5 ? 'repeat(7, 1fr)' : isP8 ? 'repeat(4, 1fr)' : '';
    const boxSize = isP5 || isP8 ? 6 : 4;

    const blocks = Array.from({ length: count }).map((_, index) => ({
      key: index,
      color,
    }));

    const springs = useSprings(
      blocks.length,
      blocks.map(() => ({
        from: { opacity: 0, transform: 'scale(0)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { tension: 200, friction: 10 },
        delay: 100,
      }))
    );

    return springs.map((spring, index) => (
      <animated.div
        key={index}
        style={{
          ...spring,
          width: blockWidth,
          height: 60,
          backgroundColor: color,
          margin: 5,
          position: 'relative',
        }}
      >
        {(isP5 || isP8) && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'grid',
              gridTemplateColumns: gridTemplate,
              gridTemplateRows: gridTemplate,
              gap: '2px',
              padding: '3px',
              backgroundColor: 'black',
            }}
          >
            {Array.from({ length: isP5 ? 49 : 16 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: boxSize,
                  height: boxSize,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  justifySelf: 'center',
                  alignSelf: 'center',
                }}
              />
            ))}
          </Box>
        )}
      </animated.div>
    ));
  };

  const validateForm = () => {
    const newErrors = {
      receiver: !values.receiver.trim(),
      date: !values.date,
      devices: !['ORANGE', 'PLAY', 'PLUS', 'p5', 'p8'].some(device => parseInt(values[device] || '0') > 0),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbarMessage('Please fill in all required fields and select at least one device');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const submissionDate = new Date().toISOString();
    const username = localStorage.getItem('username'); 
    const devices = [
      { device_type: 'sensorbox(Orange)', quantity: parseInt(values.ORANGE || '0') },
      { device_type: 'sensorbox(Play)', quantity: parseInt(values.PLAY || '0') },
      { device_type: 'sensorbox(Plus)', quantity: parseInt(values.PLUS || '0') },
      { device_type: 'p5', quantity: parseInt(values.p5 || '0') },
      { device_type: 'p8', quantity: parseInt(values.p8 || '0') },
    ];

    const submissions = devices
      .filter(device => device.quantity > 0)
      .map(device => ({
        submission_date: submissionDate,
        recipient: values.receiver,
        planned_shipping_date: values.date ? values.date.toISOString().split('T')[0] : null,
        comments: values.comments,
        device_type: device.device_type,
        quantity: device.quantity,
        details: [{}],
        status: 'utworzone',  
        username: username,   
      }));

    const payload = {
      submissions: submissions,  
    };

    try {
      const response = await publicInstance.post('/rpc/insert_multiple_shipments', payload);
      console.log('Response:', response.data);
      setSnackbarMessage('Shipment submitted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setValues(initialValues); 
    } catch (error) {
      console.error('Error submitting shipment:', error);
      setSnackbarMessage('Error submitting shipment');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };



  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleReset = () => {
    setValues({
      ...values,
      ORANGE: '',
      PLAY: '',
      PLUS: '',
      p5: '',
      p8: '',
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Button onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back
      </Button>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Shipment Receiver *</Typography>
          <TextField
            name="receiver"
            value={values.receiver}
            onChange={handleChange}
            sx={{ width: "100%" }}
            error={errors.receiver}
            helperText={errors.receiver ? "Receiver is required" : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Planned Shipment Date *</Typography>
          <DatePicker
            selected={values.date}
            onChange={handleDateChange}
            customInput={
              <TextField
                error={errors.date}
                helperText={errors.date ? "Date is required" : ""}
              />
            }
            dateFormat="yyyy-MM-dd"
            wrapperClassName="datePicker"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showComments}
                onChange={() => setShowComments(!showComments)}
                color="primary"
              />
            }
            label="Comments"
          />
          <br></br>
          {showComments && (
            <TextField
              name="comments"
              value={values.comments}
              onChange={handleChange}
              sx={{ width: "50vh" }}
              multiline
              rows={4}
            />
          )}
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Number of Devices *</Typography>
        {errors.devices && (
          <Typography color="error" variant="caption">
            At least one device must be selected
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {['ORANGE', 'PLAY', 'PLUS'].map((sensor) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }} key={sensor}>
              <Typography variant="subtitle2">{`sensorbox(${sensor})`}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onMouseDown={() => {
                    const stopInterval = incrementDecrementContinuous(sensor, 'decrement');
                    window.addEventListener('mouseup', stopInterval, { once: true });
                  }}
                >
                  <Remove />
                </IconButton>
                <TextField
                  type="text"
                  name={sensor}
                  value={values[sensor]}
                  onChange={handleChange}
                  inputProps={{ maxLength: 4, style: { textAlign: 'center', width: '5ch' }, min: 0 }}
                  sx={{ width: '70px', marginX: 1 }}
                  InputProps={{ inputProps: { style: { MozAppearance: 'textfield' } } }}
                />
                <IconButton
                  onMouseDown={() => {
                    const stopInterval = incrementDecrementContinuous(sensor, 'increment');
                    window.addEventListener('mouseup', stopInterval, { once: true });
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            </Box>
          ))}
          {['p5', 'p8'].map((sensor) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }} key={sensor}>
              <Typography variant="subtitle2">{sensor}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onMouseDown={() => {
                    const stopInterval = incrementDecrementContinuous(sensor, 'decrement');
                    window.addEventListener('mouseup', stopInterval, { once: true });
                  }}
                >
                  <Remove />
                </IconButton>
                <TextField
                  type="text"
                  name={sensor}
                  value={values[sensor]}
                  onChange={handleChange}
                  inputProps={{ maxLength: 4, style: { textAlign: 'center', width: '4ch' }, min: 0 }}
                  sx={{ width: '60px', marginX: 1 }}
                  InputProps={{ inputProps: { style: { MozAppearance: 'textfield' } } }}
                />
                <IconButton
                  onMouseDown={() => {
                    const stopInterval = incrementDecrementContinuous(sensor, 'increment');
                    window.addEventListener('mouseup', stopInterval, { once: true });
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button variant="contained" color="secondary" onClick={handleReset} sx={{ mt: 3 }}>Reset</Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: 4, backgroundColor: "background.paper", border: `2px solid ${isLightTheme ? 'lightgrey' : '#64B5F6'}`, borderRadius: "20px", padding: 2, minHeight: "20vh" }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderBlocks(parseInt(values.ORANGE || '0'), 'orange')}</Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderBlocks(parseInt(values.PLAY || '0'), 'purple')}</Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderBlocks(parseInt(values.PLUS || '0'), 'green')}</Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderBlocks(parseInt(values.p5 || '0'), 'green', true)}</Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderBlocks(parseInt(values.p8 || '0'), 'red', false, true)}</Box>
      </Box>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>

      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddShipment;
