import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { UserProvider } from './Components/UserContext'; // Import UserProvider
import { APIProvider } from '@vis.gl/react-google-maps';

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <APIProvider 
      apiKey={'AIzaSyA9sJAMsmHNVXbfIaw1VCzg-GwPYKjkvKU'} 
      onLoad={() => console.log('Maps API has loaded.')}
      libraries={['marker']}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </APIProvider>
  </React.StrictMode>
);
