import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbar, GridPaginationModel } from '@mui/x-data-grid';
import { Snackbar, Alert, Container, Box } from '@mui/material';
import { useTheme, styled, ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';

import { publicInstance } from '../axiosInstance';

interface ReusableTableProps {
  fetchUrl: string;
  columns: GridColDef[];
  search: string;
  idColumn?: string;  // Opcjonalny prop dla ustawienia innego ID kolumny
  initialSort?: { field: string; sort: 'asc' | 'desc' };
}

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 150px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '& .MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0 !important',
  },
}));

const ReusableTable: React.FC<ReusableTableProps> = ({ fetchUrl, columns, search, idColumn, initialSort }) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ pageSize: 100, page: 0 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await publicInstance.get(fetchUrl);
        const data = response.data.all_sensorbox_data || response.data.sensor_boxes || response.data.all_bts_data || response.data.all_gps_data || response.data.all_board_data || response.data;

       
        const processedData = data.map(row => ({
          ...row,
          sensors: JSON.stringify(row.sensors),
          number_of_sensors: JSON.stringify(row.number_of_sensors),
          number_of_channels: JSON.stringify(row.number_of_channels),
          antennas: JSON.stringify(row.antennas),
        }));

        setRows(processedData);
        setFilteredRows(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching data');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchUrl]);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    setFilteredRows(rows.filter((row: any) => {
        const boxValue = row.box != null ? row.box.toString() : '';
        return boxValue.includes(lowercasedSearch);
    }));
}, [search, rows]);


  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleExportToCSV = () => {
    const csvContent = convertToCSV(filteredRows);
    downloadCSV(csvContent);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) =>
      Object.values(row)
        .map((value) => (typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value))
        .join(',')
    );
    return [header, ...rows].join('\n');
  };

  const downloadCSV = (csvContent) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth={false}>
        
        <StyledBox>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
            loading={loading}
            initialState={{
              sorting: {
                sortModel: initialSort ? [{ field: initialSort.field, sort: initialSort.sort }] : [],
              },
              density: 'comfortable',
            }}
            slots={{
              toolbar: () => (
                <GridToolbar>
                  <button onClick={handleExportToCSV}>Export to CSV</button>
                </GridToolbar>
              ),
            }}
            getRowId={(row) => row[idColumn || 'box']}
            sx={{
              '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-toolbarContainer': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .boxCell': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem',
                paddingLeft: '64px',
              },
            }}
          />
        </StyledBox>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default ReusableTable;
