import React, { useState, useMemo } from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton, Tab, Tabs, Card, CardContent, useTheme, useMediaQuery, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Pagination } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { FaSearch } from 'react-icons/fa';

type Shipment = {
  id: number;
  group_id: number;
  submission_date: string;
  recipient: string;
  planned_shipping_date: string;
  comments: string;
  device_type: string;
  quantity: number;
  details: any[];
  username: string;
  status: string;
  time_stamp: string;
  deletion_time: string;
};

type ShipmentHistoryProps = {
  shipments: Shipment[];
  onClose: () => void;
};

type GroupedShipments = {
  [key: number]: Shipment[];
};

const groupDetails = (details: any[]) => {
  const groupedByOperator: { [key: string]: string[] } = {};
  const groupedByBoardType: { [key: string]: string[] } = {};

  details.forEach(detail => {
    if (detail.operator) {
      if (!groupedByOperator[detail.operator]) {
        groupedByOperator[detail.operator] = [];
      }
      groupedByOperator[detail.operator].push(Array.isArray(detail.box) ? detail.box.join(', ') : detail.box);
    }

    if (detail.board_type) {
      if (!groupedByBoardType[detail.board_type]) {
        groupedByBoardType[detail.board_type] = [];
      }
      groupedByBoardType[detail.board_type].push(Array.isArray(detail.mac_address) ? detail.mac_address.join(', ') : detail.mac_address);
    }
  });

  return { groupedByOperator, groupedByBoardType };
};

const getBorderStyle = (operator: string | undefined, boardType: string | undefined, isLightTheme: boolean) => {
  if (operator === 'Orange') return '4px solid orange';
  if (operator === 'Play') return '4px solid purple';
  if (operator === 'Plus') return '4px solid green';
  if (boardType === 'p8') return `4px dashed  ${isLightTheme ? 'black' : 'white'}`;
  if (boardType === 'p5') return `4px dotted ${isLightTheme ? 'black' : 'white'}`;
  return `4px solid ${isLightTheme ? 'grey' : 'darkgrey'}`;
};

const ShipmentHistory: React.FC<ShipmentHistoryProps> = ({ shipments, onClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('all');
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // You can adjust this value as needed
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1); // Reset page to 1 when changing tabs
  };

  const groupShipments = (shipments: Shipment[]): GroupedShipments => {
    return shipments.reduce((acc: GroupedShipments, shipment: Shipment) => {
      if (!acc[shipment.group_id]) {
        acc[shipment.group_id] = [];
      }
      acc[shipment.group_id].push(shipment);
      return acc;
    }, {});
  };

  const filteredGroupedShipments = useMemo(() => {
    const filtered = shipments.filter(shipment => {
      const searchLower = searchTerm.toLowerCase();
      switch (searchField) {
        case 'group_id':
          return shipment.group_id.toString().includes(searchTerm);
        case 'recipient':
          return shipment.recipient.toLowerCase().includes(searchLower);
        case 'mac_address':
          return shipment.details.some(detail => detail.mac_address && detail.mac_address.toLowerCase().includes(searchLower));
        case 'box':
          return shipment.details.some(detail => detail.box && detail.box.toLowerCase().includes(searchLower));
        case 'all':
        default:
          return shipment.group_id.toString().includes(searchTerm) ||
            shipment.recipient.toLowerCase().includes(searchLower) ||
            shipment.details.some(detail =>
              (detail.mac_address && detail.mac_address.toLowerCase().includes(searchLower)) ||
              (detail.box && detail.box.toLowerCase().includes(searchLower))
            );
      }
    });
    return groupShipments(filtered);
  }, [shipments, searchTerm, searchField]);

  const sentGroupedShipments = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filteredGroupedShipments).filter(([_, group]) => group[0].status === 'wyslane')
    );
  }, [filteredGroupedShipments]);

  const cancelledGroupedShipments = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filteredGroupedShipments).filter(([_, group]) => group[0].status === 'anulowane')
    );
  }, [filteredGroupedShipments]);

  const currentGroupedShipments = tabValue === 0 ? sentGroupedShipments : cancelledGroupedShipments;

  const paginatedGroupedShipments = useMemo(() => {
    const groupIds = Object.keys(currentGroupedShipments).map(Number).sort((a, b) => b - a);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedGroupIds = groupIds.slice(startIndex, endIndex);
    return paginatedGroupIds.reduce((acc: GroupedShipments, groupId) => {
      acc[groupId] = currentGroupedShipments[groupId];
      return acc;
    }, {});
  }, [currentGroupedShipments, page]);

  const totalPages = useMemo(() => {
    return Math.ceil(Object.keys(currentGroupedShipments).length / itemsPerPage);
  }, [currentGroupedShipments]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderShipmentCard = (groupId: number, group: Shipment[]) => {
    const { groupedByOperator, groupedByBoardType } = groupDetails(group[0].details);
    const isDarkTheme = theme.palette.mode === 'dark';

    return (
      <Card key={groupId} sx={{
        mb: 2,
        boxShadow: 3,
        '&:hover': { boxShadow: 6 },
      }}>
        <CardContent>
          <Typography variant="h6" color="primary">Group ID: {groupId}</Typography>
          <Typography><strong>Recipient:</strong> {group[0].recipient}</Typography>
          <Typography><strong>Planned Shipping Date:</strong> {group[0].planned_shipping_date}</Typography>
          {group.map((item, idx) => (
            <Typography key={idx}>
              <strong>{item.device_type}:</strong> {item.quantity}
            </Typography>
          ))}
          <Typography><strong>Status:</strong> {group[0].status}</Typography>
          <Typography><strong>Submission Date:</strong> {new Date(group[0].submission_date).toLocaleString()}</Typography>
          <Typography><strong>Deletion Time:</strong> {new Date(group[0].deletion_time).toLocaleString()}</Typography>
          {group[0].comments && <Typography><strong>Comments:</strong> {group[0].comments}</Typography>}
          <Typography variant="subtitle2" sx={{ mt: 1 }}>Details:</Typography>
          {Object.keys(groupedByOperator).map((operator, idx) => (
            <Box key={idx} sx={{ mb: 1, border: getBorderStyle(operator, undefined, !isDarkTheme), borderRadius: '8px', p: 1 }}>
              <Typography variant="body2">
                <strong>Operator:</strong> {operator}
              </Typography>
              <Typography variant="body2">
                Boxes: {groupedByOperator[operator].join(', ')}
              </Typography>
            </Box>
          ))}
          {Object.keys(groupedByBoardType).map((boardType, idx) => (
            <Box key={idx} sx={{ mb: 1, border: getBorderStyle(undefined, boardType, !isDarkTheme), borderRadius: '8px', p: 1 }}>
              <Typography variant="body2">
                <strong>Board Type:</strong> {boardType}
              </Typography>
              <Typography variant="body2">
                MAC Addresses: {groupedByBoardType[boardType].join(', ')}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.background.default 
    }}>

      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <IoMdClose />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
          Shipment History
        </Typography>
      </Toolbar>

      <Box sx={{ p: 2, backgroundColor: theme.palette.background.default, display: 'flex', gap: 2 }}>
        <FormControl variant="outlined" sx={{ minWidth: 200, backgroundColor: theme.palette.background.default }}>
          <InputLabel>Search by</InputLabel>
          <Select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            label="Search by"
          >
            <MenuItem value="all">All Fields</MenuItem>
            <MenuItem value="group_id">Group ID</MenuItem>
            <MenuItem value="recipient">Recipient</MenuItem>
            <MenuItem value="mac_address">MAC Address</MenuItem>
            <MenuItem value="box">Box Number</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={`Search by ${searchField === 'all' ? 'any field' : searchField.replace('_', ' ')}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="shipment history tabs"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Sent Shipments" />
        <Tab label="Cancelled Shipments" />

      </Tabs>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        {tabValue === 0 && (
          <>
            <Typography variant="h5" gutterBottom>Sent Shipments ({Object.keys(sentGroupedShipments).length})</Typography>
            {Object.entries(paginatedGroupedShipments).map(([groupId, group]) => renderShipmentCard(Number(groupId), group))}
          </>
        )}
        {tabValue === 1 && (
          <>
            <Typography variant="h5" gutterBottom>Cancelled Shipments ({Object.keys(cancelledGroupedShipments).length})</Typography>
            {Object.entries(paginatedGroupedShipments).map(([groupId, group]) => renderShipmentCard(Number(groupId), group))}
          </>
        )}
      </Box>

      <Box sx={{ 
        p: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        backgroundColor: theme.palette.background.default,
        display: 'flex', 
        justifyContent: 'center'
      }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          
        />
      </Box>
    </Box>
  );
};

export default ShipmentHistory;