import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance, { publicInstance } from '../axiosInstance';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import BoxNumberSearch from './BoxNumberSearch';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { format, parseISO } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Tooltip from '@mui/material/Tooltip';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

function MapEventHandler({ onCameraChange }: { onCameraChange: (center: [number, number], zoom: number) => void }) {
    const map = useMapEvents({
        moveend: () => {
            const center = map.getCenter();
            onCameraChange([center.lat, center.lng], map.getZoom());
        },
        zoomend: () => {
            const center = map.getCenter();
            onCameraChange([center.lat, center.lng], map.getZoom());
        },
    });
    return null;
}

function createCustomIcon() {
  return new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
}

function InstalationDetailPage() {
    const theme = useTheme();
    const { boxNumber } = useParams();
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState<Record<string, any> | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState<string>('Checking connection...');
    const [loadingConnection, setLoadingConnection] = useState<boolean>(true);
    const [serviceStatus, setServiceStatus] = useState<string>('');
    const [fetchStatus, setFetchStatus] = useState<string>('');
    const [dots, setDots] = useState<string>('');
    const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
    const [rebooting, setRebooting] = useState<boolean>(false);
    const [restartingService, setRestartingService] = useState<boolean>(false);
    const [gpsData, setGpsData] = useState<{ lat: number, lng: number, timestamp: string } | null>(null);
    const [mapInitialized, setMapInitialized] = useState(false);
    

    const [cameraProps, setCameraProps] = useState({
        center: [0, 0] as [number, number],
        zoom: 17,
    });

    useEffect(() => {
        if (gpsData && !mapInitialized) {
            setCameraProps(prev => ({
                ...prev,
                center: [gpsData.lat, gpsData.lng]
            }));
            setMapInitialized(true);
        }
    }, [gpsData, mapInitialized]);

    const handleCameraChange = (newCenter: [number, number], newZoom: number) => {
        setCameraProps({
            center: newCenter,
            zoom: newZoom,
        });
    };

    const resetMapCamera = () => {
        if (gpsData) {
            setCameraProps({
                center: [gpsData.lat, gpsData.lng],
                zoom: 17,
            });
        }
    };

    const isDarkMode = theme.name === 'dark';

    const buttonStyle = {
        '&.MuiButton-root': {
            backgroundColor: isDarkMode ? '#333333' : '#1976D2',
            color: isDarkMode ? '#E0E0E0' : '#FFFFFF',
            '&:hover': {
                backgroundColor: isDarkMode ? '#444444' : '#1565C0',
            },
            minWidth: '150px',
            margin: '8px',
        },
    };

    const disabledButtonStyle = {
        '&.MuiButton-root': {
            backgroundColor: isDarkMode ? '#555555' : '#E0E0E0',
            color: isDarkMode ? '#B0B0B0' : '#9E9E9E', // Zmieniono kolor tekstu, aby był widoczny w trybie ciemnym
            border: `1px solid ${isDarkMode ? '#777777' : '#CCCCCC'}`,
            opacity: 0.5,
            cursor: 'not-allowed',
            minWidth: '150px',
            margin: '8px',
        },
        '&.Mui-disabled': {
            backgroundColor: isDarkMode ? '#555555' : '#E0E0E0',
            color: isDarkMode ? '#B0B0B0' : '#9E9E9E',
        },
    };

    const customAlertStyle = {
        backgroundColor: isDarkMode ? '#333' : '#E0F7FA',  // Ciemne tło dla dark mode
        color: isDarkMode ? '#A8DADC' : '#00796B',  // Kolor tekstu
        border: isDarkMode ? '1px solid #457B9D' : '1px solid #004D40',  // Obwódka
        borderRadius: '8px',  // Zaokrąglenie rogów
        padding: '6px 16px',  // Adjust this value as needed
        marginTop: '-4px',    // Add a small negative margin if needed
    };

    const ranges = {
        "pm25": [0, 200],
        "pm10": [0, 200],
        "dust temp": [10, 50],
        "dust humid": [10, 80],
        "ambient temp": [-20, 40],
        "ambient press": [900, 1200],
        "ambient humid": [20, 90],
        "dust heater ntc": [10, 70],
        "dust rpm": [4450, 4550],
    };

    const getAdditionalDataColor = (key: string, value: string | undefined) => {
        if (typeof value !== 'string') {
            return 'black';
        }

        switch (key) {
            case 'network':
                return value.includes('OK') ? 'green' : 'red';
            case 'OSE_ESA':
                return value === 'OK' ? 'green' : 'red';
            case 'Displays MAC':
                return value !== 'N/A' ? 'green' : 'red';
            case 'SIM':
                if (value === 'ORANGE') return 'orange';
                if (value === 'PLUS') return 'green';
                if (value === 'PLAY') return 'violet';
                return 'red';
            case 'MODEM':
                return 'green';
            default:
                return 'black';
        }
    };

    useEffect(() => {
        const dotInterval = setInterval(() => {
            setDots(prevDots => (prevDots.length === 3 ? '' : prevDots + '.'));
        }, 500);

        return () => clearInterval(dotInterval);
    }, []);

    useEffect(() => {
        resetStates();
        const source = axios.CancelToken.source();
        setCancelSource(source);
        checkConnection(source);

        // Reset map-related states
        setGpsData(null);
        setMapInitialized(false);
        setCameraProps({
            center: [0, 0],
            zoom: 17,
        });

        if (boxNumber) {
            fetchGpsData(boxNumber);
        }

        return () => {
            source.cancel('Operation canceled due to box number change.');
        };
    }, [boxNumber]);

    useEffect(() => {
        delete (L.Icon.Default.prototype as any)._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: markerIcon,
            iconUrl: markerIcon,
            shadowUrl: markerShadow,
        });
    }, []);

    const resetStates = () => {
        setResponseData(null);
        setLoading(false);
        setError(null);
        setConnectionStatus('Checking connection...');
        setLoadingConnection(true);
        setServiceStatus('');
        setFetchStatus('');
    };

    const checkConnection = async (source: any) => {
        try {
            const response = await axiosInstance.get(`/serwis/run-check-connection/${boxNumber}`, {
                cancelToken: source.token,
            });
            if (response.data.status === "connection successful") {
                setConnectionStatus("Connection successful");
                setLoadingConnection(false);
                checkService(source);
            } else {
                throw new Error("Connection lost");
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request canceled', err.message);
            } else {
                setTimeout(() => checkConnection(source), 5000);
            }
        }
    };

    const checkService = async (source: any) => {
        setLoading(true);
        const intervalId = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`/serwis/run-service-check/${boxNumber}`, {
                    cancelToken: source.token,
                });
                if (response.data.output === "Service up") {
                    setServiceStatus("Service running");
                    clearInterval(intervalId);
                    // Add a 5-second pause before fetching data
                    setTimeout(() => handleButtonClick(source), 5000);
                } else if (response.data.output === "Service down") {
                    setServiceStatus("Service not running");
                    console.log('Service not running, will retry...');
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    console.log('Error while checking service status, will retry...');
                }
            }
        }, 5000);

        return () => clearInterval(intervalId);
    };

    const handleButtonClick = async (source: any) => {
        setLoading(true);
        setError(null);
        setFetchStatus('Fetching data...');

        try {
            const response = await axiosInstance.get(`/serwis/run-macio-read-box/${boxNumber}`, {
                cancelToken: source.token,
            });
            setResponseData(response.data);
            setFetchStatus('Data fetched');
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request canceled', err.message);
            } else {
                setError('An error occurred while fetching data.');
                setFetchStatus('Failed to fetch data');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeviceReboot = async () => {
        setRebooting(true);
        resetStates();  // Resetowanie wszystkich stanów
        try {
            const response = await axiosInstance.get(`/serwis/run-box-reboot/${boxNumber}`);
            if (response.data.output === "Reboot completed") {
                checkConnection(cancelSource);
            } else {
                setError("Reboot failed");
            }
        } catch (err) {
            setError("An error occurred during reboot.");
        } finally {
            setRebooting(false);
        }
    };

    const handleServiceRestart = async () => {
        setRestartingService(true);
        setServiceStatus(''); // Resetowanie statusu usługi
        setFetchStatus(''); // Resetowanie statusu pobierania
        try {
            const response = await axiosInstance.get(`/serwis/run-sv-restart/${boxNumber}`);
            if (response.data.output === "sv restart completed") {
                checkService(cancelSource);
            } else {
                setError("Service restart failed");
            }
        } catch (err) {
            setError("An error occurred during service restart.");
        } finally {
            setRestartingService(false);
        }
    };

    const handleRefresh = () => {
        setResponseData(null);
        setFetchStatus('');
        const newSource = axios.CancelToken.source();
        setCancelSource(newSource);
        handleButtonClick(newSource);
    };

    const extractMAC = (hardwareString: string | undefined): string => {
        if (!hardwareString) {
            return 'N/A';
        }
        const macMatch = hardwareString.match(/MAC\s([\dA-Fa-f:]+)/);
        return macMatch ? macMatch[1] : 'N/A';
    };

    const renderTable = (data: Record<string, any>, isMeasurement: boolean = false) => (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableBody>
                    {data && Object.entries(data).map(([key, value]) => {
                        const numericValue = parseFloat(value);
                        const [min, max] = ranges[key] || [null, null];
                        const isInRange = min !== null && max !== null && numericValue >= min && numericValue <= max;
                        const color = isMeasurement
                            ? isInRange ? 'green' : 'red'
                            : getAdditionalDataColor(key, value);

                        return (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell sx={{ color }}>
                                    {String(value)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const measurements = responseData ? {
        "pm25": responseData["pm25"],
        "pm10": responseData["pm10"],
        "dust temp": responseData["dust temp"],
        "dust humid": responseData["dust humid"],
        "ambient temp": responseData["ambient temp"],
        "ambient press": responseData["ambient press"],
        "ambient humid": responseData["ambient humid"],
        "dust heater ntc": responseData["dust heater ntc"],
        "dust rpm": responseData["dust rpm"]
    } : {};

    const additionalInfo = responseData ? {
        "network": responseData["network"],
        "OSE_ESA": responseData["OSE_ESA"],
        "Displays MAC": extractMAC(responseData["hardware"]),
        "SIM": responseData["SIM"],
        "MODEM": responseData["MODEM"]
    } : {};

    const isBoxOperational = () => {
        const measurementDataKeys = Object.keys(measurements);
        const areMeasurementsValid = measurementDataKeys.every((key) => {
            const numericValue = parseFloat(measurements[key]);
            const [min, max] = ranges[key] || [null, null];
            return min !== null && max !== null && numericValue >= min && numericValue <= max;
        });

        const additionalInfoKeys = Object.keys(additionalInfo);
        const isAdditionalInfoValid = additionalInfoKeys.every((key) => {
            const value = additionalInfo[key];
            const color = getAdditionalDataColor(key, value);
            return color !== 'red';
        });

        return areMeasurementsValid && isAdditionalInfoValid;
    };

    const fetchGpsData = async (boxNumber: string) => {
        try {
            const response = await publicInstance.get(`/dacapi_gps_current?box=eq.${boxNumber}`);
            if (response.data.length > 0) {
                const gpsString = response.data[0].gps;
                const gpsCoords = parseGps(gpsString);
                const timestamp = formatTimestamp(response.data[0].time_stamp);
                setGpsData({ ...gpsCoords, timestamp });
                setCameraProps(prev => ({
                    ...prev,
                    center: [gpsCoords.lat, gpsCoords.lng]
                }));
            }
        } catch (error) {
            console.error('Error fetching GPS data:', error);
        }
    };

    const formatTimestamp = (timestamp: string): string => {
        const date = parseISO(timestamp);
        return format(date, 'dd.MM.yyyy HH:mm:ss');
    };

    const parseGps = (gpsString: string): { lat: number, lng: number } => {
        const [latPart, lngPart] = gpsString.split(' ');
        const lat = parseFloat(latPart.slice(0, -1)) * (latPart.slice(-1) === 'N' ? 1 : -1);
        const lng = parseFloat(lngPart.slice(0, -1)) * (lngPart.slice(-1) === 'E' ? 1 : -1);
        return { lat, lng };
    };

    const generateGoogleMapsLink = (lat: number, lng: number) => {
        return `https://www.google.com/maps?q=${lat},${lng}`;
    };

    return (
        <Box sx={{ p: 4, width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <BoxNumberSearch />

            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} lg={7} order={{ xs: 1, lg: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
                        <Typography variant="h4" component="h1">
                            Dashboard for Box: {boxNumber}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            ml: { xs: 0, sm: 2 },
                            mt: { xs: 2, sm: 0 },
                            justifyContent: { xs: 'center', sm: 'flex-start' }
                        }}>
                            <Button
                                onClick={handleDeviceReboot}
                                disabled={loadingConnection || rebooting}
                                variant="contained"
                                sx={loadingConnection || rebooting ? disabledButtonStyle : buttonStyle}
                            >
                                Reboot Device
                            </Button>
                            <Button
                                onClick={handleServiceRestart}
                                disabled={serviceStatus !== "Service running" || restartingService}
                                variant="contained"
                                sx={serviceStatus !== "Service running" || restartingService ? disabledButtonStyle : buttonStyle}
                            >
                                Restart Service
                            </Button>
                            <Button
                                onClick={handleRefresh}
                                disabled={fetchStatus !== 'Data fetched' || loading}
                                variant="contained"
                                sx={fetchStatus !== 'Data fetched' || loading ? disabledButtonStyle : buttonStyle}
                                startIcon={<RefreshIcon />}
                            >
                                Refresh
                            </Button>
                        </Box>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Status
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                        <Box
                                            sx={{
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                backgroundColor: connectionStatus === "Connection successful" ? 'green' : 'red',
                                                marginRight: 2,
                                            }}
                                        />
                                        <Typography>
                                            {loadingConnection ? `Checking connection${dots}` : connectionStatus}
                                        </Typography>
                                    </Box>
                                    {connectionStatus === "Connection successful" && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                            <Box
                                                sx={{
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: '50%',
                                                    backgroundColor: serviceStatus === "Service running" ? 'green' : 'red',
                                                    marginRight: 2,
                                                }}
                                            />
                                            <Typography>
                                                {serviceStatus === "" ? `Checking service${dots}` : serviceStatus}
                                            </Typography>
                                        </Box>
                                    )}
                                    {serviceStatus === "Service running" && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                            <Box
                                                sx={{
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: '50%',
                                                    backgroundColor: fetchStatus === 'Data fetched' ? 'green' : 'red',
                                                    marginRight: 2,
                                                }}
                                            />
                                            <Typography>
                                                {loading ? `Fetching data${dots}` : fetchStatus}
                                            </Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>

                        {fetchStatus === 'Data fetched' && (
                            isBoxOperational() ? (
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100px' }}>
                                            <CheckCircleIcon style={{ color: 'green', fontSize: 50 }} />
                                            <Typography variant="h5" component="div" color="green">
                                                Box is operational
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100px' }}>
                                            <CancelIcon style={{ color: 'red', fontSize: 50 }} />
                                            <Typography variant="h5" component="div" color="red">
                                                Box is not operational.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        )}

                        {fetchStatus === 'Data fetched' && (
                            <>
                                <Grid item xs={12} lg={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" component="div" gutterBottom>
                                                Additional Data
                                            </Typography>
                                            {renderTable(additionalInfo)}
                                            {additionalInfo['Displays MAC'] === 'N/A' && (
                                                <Alert severity="info" sx={customAlertStyle}>
                                                    Często tablica włącza się dłużej niż usługa. Najlepiej poczekać, aż na tablicy pojawią się wartości i wtedy dokonać refresh danych.
                                                </Alert>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" component="div" gutterBottom>
                                                Measurement Data
                                            </Typography>
                                            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                                            {responseData && renderTable(measurements, true)}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={5} order={{ xs: 2, lg: 2 }}>
                    {gpsData ? (
                        <Box sx={{ height: { xs: '600px', lg: '100%' }, width: '100%' }}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body1">
                                        GPS: {gpsData.lat}, {gpsData.lng}
                                    </Typography>
                                    <Typography variant="body1">
                                        GPS Timestamp: {gpsData.timestamp}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1">
                                            <a
                                                href={generateGoogleMapsLink(gpsData.lat, gpsData.lng)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#1976D2', textDecoration: 'underline' }}
                                            >
                                                Open in Google Maps
                                            </a>
                                        </Typography>
                                        <Tooltip title="Reset map view to initial position" arrow>
                                            <IconButton 
                                                onClick={resetMapCamera} 
                                                size="small" 
                                                sx={{ ml: 1 }}
                                            >
                                                <CenterFocusStrongIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Alert severity="info" sx={customAlertStyle}>
                                        The map shows the last successfully read GPS data. GPS data is updated daily in the evening.
                                    </Alert>
                                </Grid>
                            </Grid>
                            <Box sx={{ height: { xs: 'calc(100% - 120px)', lg: 'calc(100% - 80px)' }, width: '100%' }}>
                                <MapContainer
                                    center={cameraProps.center}
                                    zoom={cameraProps.zoom}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    {gpsData && (
                                        <Marker position={[gpsData.lat, gpsData.lng]} icon={createCustomIcon()} />
                                    )}
                                    <MapEventHandler onCameraChange={handleCameraChange} />
                                </MapContainer>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ height: { xs: '400px', lg: '100%' }, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="body1">No GPS data available for this box number.</Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default InstalationDetailPage;