import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as MuiLink } from "@mui/material";
import { Link } from 'react-router-dom';

const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const renderContracts = (params: GridRenderCellParams) => {
  return params.value ? params.value.join(', ') : '';
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', flex: 1, renderCell: renderBoxLink },
  { field: 'has_addresses', headerName: 'Has Addresses', flex: 1 },
  { field: 'id_esa', headerName: 'ID ESA', flex: 1 },
  { field: 'id_instalacji', headerName: 'ID Instalacji', flex: 1 },
  { field: 'data_wymiany', headerName: 'Data Wymiany', flex: 1 },
  { field: 'data_montazu', headerName: 'Data Montażu', flex: 1 },
  { field: 'contracts', headerName: 'Contracts', flex: 1, renderCell: renderContracts },
];

interface Table6Props {
  search: string;
}

const Table6: React.FC<Table6Props> = ({ search }) => {
  return <ReusableTable fetchUrl="/view_dacapi_customroutes_with_contracts" columns={columns} search={search} />;
};

export default Table6;
