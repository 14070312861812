import React, { useState, useRef } from 'react';
import { Box, Typography, Card, CardContent, useTheme, useMediaQuery, Button, Checkbox, Tooltip } from '@mui/material';
import { publicInstance } from '../axiosInstance';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import CancelIcon from '@mui/icons-material/Cancel';
import { alpha } from '@mui/material/styles';


type Shipment = {
  id: number;
  group_id: number;
  recipient: string;
  planned_shipping_date: string;
  comments: string;
  status: string;
  device_type: string;
  quantity: number;
  details: any[];
};

type GroupedShipments = {
  [key: number]: Shipment[];
};

type CompletedShipmentsProps = {
  shipments: Shipment[];
  onUpdateCompletedShipments: (groupId: number) => void;
  onMoveToCurrentShipments: (shipment: Shipment[]) => void;
  showSnackbar: (message: string, severity: 'success' | 'error' | 'warning' | 'info') => void;
};

const groupDetails = (details: any[]) => {
  const groupedByOperator: { [key: string]: string[] } = {};
  const groupedByBoardType: { [key: string]: string[] } = {};

  details.forEach(detail => {
    if (detail.operator) {
      if (!groupedByOperator[detail.operator]) {
        groupedByOperator[detail.operator] = [];
      }
      groupedByOperator[detail.operator].push(Array.isArray(detail.box) ? detail.box.join(', ') : detail.box);
    }

    if (detail.board_type) {
      if (!groupedByBoardType[detail.board_type]) {
        groupedByBoardType[detail.board_type] = [];
      }
      groupedByBoardType[detail.board_type].push(Array.isArray(detail.mac_address) ? detail.mac_address.join(', ') : detail.mac_address);
    }
  });

  return { groupedByOperator, groupedByBoardType };
};

const getBorderStyle = (operator: string | undefined, boardType: string | undefined, isLightTheme: boolean) => {
  if (operator === 'Orange') return '4px solid orange';
  if (operator === 'Play') return '4px solid purple';
  if (operator === 'Plus') return '4px solid green';
  if (boardType === 'p8') return `4px dashed  ${isLightTheme ? 'black' : 'white'}`;
  if (boardType === 'p5') return `4px dotted ${isLightTheme ? 'black' : 'white'}`;
  return `4px solid ${isLightTheme ? 'grey' : 'darkgrey'}`;
};

const PrintableCards = React.forwardRef<HTMLDivElement, { shipments: Shipment[], isLightTheme: boolean }>(({ shipments, isLightTheme }, ref) => {
  return (
    <div ref={ref} style={{ fontFamily: 'Arial, sans-serif', color: 'black', backgroundColor: 'white', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Shipment Details</h1>
      {shipments.map((shipment, index) => {
        const { groupedByOperator, groupedByBoardType } = groupDetails(shipment.details);

        return (
          <div key={index} style={{
            pageBreakInside: 'avoid',
            border: '2px solid #000',
            
            padding: '20px',
            marginBottom: '40px',
            maxWidth: '210mm',
            margin: '0 auto'
          }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', padding: '5px', border: '1px solid #ddd', width: '25%' }}>Group ID</td>
                  <td style={{ padding: '5px', border: '1px solid #ddd', width: '25%' }}>{shipment.group_id}</td>
                  <td style={{ fontWeight: 'bold', padding: '5px', border: '1px solid #ddd', width: '25%' }}>Recipient</td>
                  <td style={{ 
                    padding: '5px', 
                    border: '1px solid #ddd', 
                    width: '25%', 
                    wordBreak: 'break-word', 
                    fontSize: shipment.recipient.length > 30 ? '0.8em' : '1em' 
                  }}>
                    {shipment.recipient}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', padding: '5px', border: '1px solid #ddd' }}>Planned Shipping Date</td>
                  <td style={{ padding: '5px', border: '1px solid #ddd' }}>{shipment.planned_shipping_date}</td>
                  <td style={{ fontWeight: 'bold', padding: '5px', border: '1px solid #ddd' }}>Status</td>
                  <td style={{ padding: '5px', border: '1px solid #ddd' }}>{shipment.status}</td>
                </tr>
              </tbody>
            </table>

            <h3 style={{ marginBottom: '10px' }}>Device Types</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
              {Object.entries(groupedByOperator).map(([operator, boxes]) => (
                <div key={operator} style={{
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  padding: '10px',
                  backgroundColor: operator === 'Orange' ? '#FFF3E0' : operator === 'Play' ? '#EDE7F6' : operator === 'Plus' ? '#E8F5E9' : '#F5F5F5'
                }}>
                  <strong>{operator}:</strong> {boxes.length} sensorbox(es)
                </div>
              ))}
              {Object.entries(groupedByBoardType).map(([boardType, macs]) => (
                <div key={boardType} style={{
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  padding: '10px',
                  backgroundColor: boardType === 'p8' ? '#FFEBEE' : boardType === 'p5' ? '#E0F2F1' : '#F5F5F5'
                }}>
                  <strong>{boardType}:</strong> {macs.length} device(s)
                </div>
              ))}
            </div>

            <h3 style={{ marginBottom: '10px' }}>Details</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
              <div style={{ flex: 1, minWidth: '200px' }}>
                <h4>Operators</h4>
                {Object.entries(groupedByOperator).map(([operator, boxes]) => (
                  <div key={operator} style={{ marginBottom: '10px' }}>
                    <strong>{operator}:</strong>
                    <div style={{ marginLeft: '10px' }}>Boxes: {boxes.join(', ')}</div>
                  </div>
                ))}
              </div>
              <div style={{ flex: 1, minWidth: '200px' }}>
                <h4>Board Types</h4>
                {Object.entries(groupedByBoardType).map(([boardType, macs]) => (
                  <div key={boardType} style={{ marginBottom: '10px' }}>
                    <strong>{boardType}:</strong>
                    <div style={{ marginLeft: '10px' }}>MAC Addresses: {macs.join(', ')}</div>
                  </div>
                ))}
              </div>
            </div>

            {shipment.comments && (
              <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                <strong>Comments:</strong> {shipment.comments}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});



const CompletedShipments: React.FC<CompletedShipmentsProps> = ({ shipments, onUpdateCompletedShipments, onMoveToCurrentShipments, showSnackbar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkTheme = theme.name === 'dark';

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const printRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const groupedShipments: GroupedShipments = shipments.reduce((acc: GroupedShipments, shipment: Shipment) => {
    if (!acc[shipment.group_id]) {
      acc[shipment.group_id] = [];
    }
    acc[shipment.group_id].push(shipment);
    return acc;
  }, {});

  const sortedGroupIds = Object.keys(groupedShipments)
    .map(Number)
    .sort((a, b) => b - a);

  const hasShipments = sortedGroupIds.length > 0;

  const handleStatusChange = async (groupId: number, status: string) => {
    try {
      await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, { status });
      await publicInstance.delete(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`);
      onUpdateCompletedShipments(groupId);
      showSnackbar(`Status updated to ${status} and shipment deleted successfully.`, 'success');
    } catch (error) {
      console.error(`Error updating status to ${status} and deleting shipment:`, error);
      showSnackbar(`Failed to update status to ${status} or delete shipment.`, 'error');
    }
  };

  const toggleSelectionMode = () => {
    setIsSelecting(!isSelecting);
    setSelectedGroupIds([]);
  };

  const handleSelectGroup = (groupId: number) => {
    setSelectedGroupIds(prev =>
      prev.includes(groupId) ? prev.filter(id => id !== groupId) : [...prev, groupId]
    );
  };

  const handleSelectAll = () => {
    if (selectedGroupIds.length === sortedGroupIds.length) {
      setSelectedGroupIds([]);
    } else {
      setSelectedGroupIds(sortedGroupIds);
    }
  };

  const handleShipment = async (groupId: number) => {
    try {
      await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, { status: 'wyslane' });
      await publicInstance.delete(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`);
      onUpdateCompletedShipments(groupId);
      showSnackbar('Shipment marked as sent successfully.', 'success');
    } catch (error) {
      console.error('Error marking shipment as sent:', error);
      showSnackbar('Failed to mark shipment as sent.', 'error');
    }
  };

  const handleMoveToCurrentShipments = async (groupId: number, group: Shipment[]) => {
    try {
      await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, { status: 'utworzone' });
      
      onUpdateCompletedShipments(groupId);
      onMoveToCurrentShipments(group);
      showSnackbar('Shipment moved back to current shipments successfully.', 'success');
    } catch (error) {
      console.error('Error moving shipment to current shipments:', error);
      showSnackbar('Failed to move shipment to current shipments.', 'error');
    }
  };

  const handleCancelShipment = async (groupId: number) => {
    try {
      await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, { status: 'anulowane' });
      await publicInstance.delete(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`);
      onUpdateCompletedShipments(groupId);
      showSnackbar('Shipment cancelled successfully.', 'success');
    } catch (error) {
      console.error('Error cancelling shipment:', error);
      showSnackbar('Failed to cancel shipment.', 'error');
    }
  };

  const buttonStyle = {
    '&.MuiButton-root': {
      color: theme.palette.primary.contrastText,
      backgroundColor: isDarkTheme ? theme.palette.primary.dark : theme.palette.primary.main,
      fontSize: '0.8rem',
      padding: '6px 12px',
      minWidth: '120px',
      width: '120px',  // Added fixed width
      height: '32px',
      whiteSpace: 'nowrap',  // Prevent text wrapping
      overflow: 'hidden',    // Hide overflowing text
      textOverflow: 'ellipsis',  // Show ellipsis for overflowing text
      '&:hover': {
        backgroundColor: isDarkTheme ? theme.palette.primary.main : theme.palette.primary.dark,
      },
    },
  };

  const secondaryButtonStyle = {
    '&.MuiButton-root': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: isDarkTheme ? theme.palette.secondary.dark : theme.palette.secondary.main,
      fontSize: '0.8rem',
      padding: '6px 12px',
      minWidth: '120px',
      width: '120px',  // Added fixed width
      height: '32px',
      whiteSpace: 'nowrap',  // Prevent text wrapping
      overflow: 'hidden',    // Hide overflowing text
      textOverflow: 'ellipsis',  // Show ellipsis for overflowing text
      '&:hover': {
        backgroundColor: isDarkTheme ? theme.palette.secondary.main : theme.palette.secondary.dark,
      },
    },
  };

  const cancelButtonStyle = {
    '&.MuiButton-root': {
      color: theme.palette.error.contrastText,
      backgroundColor: isDarkTheme ? theme.palette.error.dark : theme.palette.error.main,
      fontSize: '0.8rem',
      padding: '6px 12px',
      minWidth: '120px',
      width: '120px',  // Added fixed width
      height: '32px',
      whiteSpace: 'nowrap',  // Prevent text wrapping
      overflow: 'hidden',    // Hide overflowing text
      textOverflow: 'ellipsis',  // Show ellipsis for overflowing text
      '&:hover': {
        backgroundColor: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', mb: isMobile ? 4 : 0 }}>
      <Typography variant="h4" gutterBottom>
        Completed Shipments ({sortedGroupIds.length})
      </Typography>
      {hasShipments && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              variant={isSelecting ? "contained" : "outlined"}
              color={isSelecting ? "secondary" : "primary"}
              onClick={toggleSelectionMode}
              size="small"
              startIcon={isSelecting ? <CancelIcon /> : <PrintIcon />}
            >
              {isSelecting ? 'Cancel Selection' : 'Select for Printing'}
            </Button>

            {isSelecting && (
              <Typography variant="body2" color="textSecondary">
                {selectedGroupIds.length} shipment(s) selected
              </Typography>
            )}
          </Box>
          {isSelecting && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSelectAll}
                size="small"
              >
                {selectedGroupIds.length === sortedGroupIds.length ? 'Deselect All' : 'Select All'}
              </Button>
              {selectedGroupIds.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePrint}
                  size="small"
                  startIcon={<PrintIcon />}
                >
                  Print Selected
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: isMobile ? '60vh' : '80vh',
      }}>
        {sortedGroupIds.map((groupId) => {
          const group = groupedShipments[groupId];
          const { groupedByOperator, groupedByBoardType } = groupDetails(group[0].details);
          const isSelected = selectedGroupIds.includes(groupId);

          return (
            <Card
              key={groupId}
              onClick={() => isSelecting && handleSelectGroup(groupId)}
              sx={{
                mb: 2,
                p: 2,
                backgroundColor: isSelected
                  ? isDarkTheme ? 'rgba(255, 255, 255, 0.08)' : theme.palette.action.selected
                  : theme.palette.background.paper,
                borderRadius: '8px',
                cursor: isSelecting ? 'pointer' : 'default',
                border: isSelecting
                  ? isDarkTheme ? '2px solid rgba(255, 255, 255, 0.23)' : `2px solid ${theme.palette.primary.main}`
                  : 'none',
                transition: 'all 0.3s ease',
                position: 'relative',
                overflow: 'hidden',
                '&:hover': isSelecting ? {
                  backgroundColor: isDarkTheme ? 'rgba(255, 255, 255, 0.12)' : theme.palette.action.hover,
                  '&::before, &::after': {
                    transform: 'scaleX(0)',
                  },
                } : {},
                '&::before, &::after': isSelecting ? {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '2px',
                  backgroundColor: isDarkTheme ? 'rgba(255, 255, 255, 0.23)' : theme.palette.primary.main,
                  transition: 'transform 0.3s ease',
                } : {},
                '&::before': {
                  left: 0,
                  transformOrigin: 'left',
                },
                '&::after': {
                  right: 0,
                  transformOrigin: 'right',
                },
              }}
            >
              {isSelecting && (
                <Checkbox
                  checked={isSelected}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                    color: isDarkTheme ? 'rgba(255, 255, 255, 0.7)' : undefined,
                    '&.Mui-checked': {
                      color: isDarkTheme ? theme.palette.primary.light : undefined,
                    },
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleSelectGroup(groupId)}
                />
              )}
              <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', wordBreak: 'break-word' }}>
                <Box sx={{ flexGrow: 1, mr: 2 }}>
                  <Typography variant="body2">
                    <strong>Group ID:</strong> {group[0].group_id}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Recipient:</strong> {group[0].recipient}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Planned Shipping Date:</strong> {group[0].planned_shipping_date}
                  </Typography>
                  {group.map((item, idx) => (
                    <Typography key={idx} variant="body2">
                      <strong>{item.device_type}:</strong> {item.quantity}
                    </Typography>
                  ))}
                  <Typography variant="body2">
                    <strong>Comments:</strong> {group[0].comments}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {group[0].status}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Details:</strong>
                  </Typography>
                  {Object.keys(groupedByOperator).map((operator, idx) => (
                    <Box key={idx} sx={{ mb: 1, border: getBorderStyle(operator, undefined, !isDarkTheme), borderRadius: '8px', p: 1 }}>
                      <Typography variant="body2">
                        <strong>Operator:</strong> {operator}
                      </Typography>
                      <Typography variant="body2">
                        Boxes: {groupedByOperator[operator].join(', ')}
                      </Typography>
                    </Box>
                  ))}
                  {Object.keys(groupedByBoardType).map((boardType, idx) => (
                    <Box key={idx} sx={{ mb: 1, border: getBorderStyle(undefined, boardType, !isDarkTheme), borderRadius: '8px', p: 1 }}>
                      <Typography variant="body2">
                        <strong>Board Type:</strong> {boardType}
                      </Typography>
                      <Typography variant="body2">
                        MAC Addresses: {groupedByBoardType[boardType].join(', ')}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {!isSelecting && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, minWidth: '120px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleShipment(group[0].group_id)}
                      fullWidth
                      sx={buttonStyle}
                    >
                      Mark as Sent
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleMoveToCurrentShipments(group[0].group_id, group)}
                      fullWidth
                      sx={{
                        ...secondaryButtonStyle,
                        '&.MuiButton-root': {
                          ...secondaryButtonStyle['&.MuiButton-root'],
                          fontSize: '0.70rem',  // Slightly reduced font size
                        },
                      }}
                    >
                      Move to Current
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleCancelShipment(group[0].group_id)}
                      fullWidth
                      sx={cancelButtonStyle}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </CardContent>
              {isSelected && (
                <div style={{ display: 'none' }}>
                  <PrintableCards ref={printRef} shipments={selectedGroupIds.map(id => groupedShipments[id][0])} isLightTheme={!isDarkTheme} />
                </div>
              )}
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default CompletedShipments;
