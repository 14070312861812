import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import L from 'leaflet';

interface GPSInformationProps {
  gpsData?: any;
  latitude?: number;
  longitude?: number;
}

const GPSInformation: React.FC<GPSInformationProps> = ({ gpsData, latitude, longitude }) => {
  const defaultPosition: [number, number] = [52.237049, 21.017532]; // Center of Poland
  const defaultZoomLevel = 5;
  const markerZoomLevel = 10;

  const [mapCenter, setMapCenter] = useState<[number, number]>(defaultPosition);
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);

  const modem = gpsData?.modem_gps || "Unknown";
  const gps = gpsData?.gps || "Unknown";
  const timeStamp = gpsData?.time_stamp_gps ? new Date(gpsData.time_stamp_gps).toLocaleString() : "Unknown";

  const resetMap = () => {
    setMapCenter(defaultPosition);
    setZoomLevel(defaultZoomLevel);
  };

  const zoomToMarker = () => {
    if (latitude !== undefined && longitude !== undefined) {
      setMapCenter([latitude, longitude]);
      setZoomLevel(markerZoomLevel);
    }
  };

  function MapController() {
    const map = useMap();
    map.setView(mapCenter, zoomLevel);
    return null;
  }

  return (
    <Card>
      <CardHeader title="GPS Information" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Modem</Typography>
            <Typography variant="body2">{modem}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>GPS</Typography>
            <Typography variant="body2">{gps}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Last Updated</Typography>
            <Typography variant="body2">{timeStamp}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <IconButton color="primary" onClick={resetMap}>
            <ZoomOut />
          </IconButton>
          <IconButton color="primary" onClick={zoomToMarker}>
            <ZoomInIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: '300px', width: '100%', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}>
          <MapContainer center={defaultPosition} zoom={defaultZoomLevel} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapController />
            {latitude !== undefined && longitude !== undefined && (
              <Marker position={[latitude, longitude]} icon={L.icon({
                iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })} />
            )}
          </MapContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GPSInformation;
