import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as MuiLink } from "@mui/material";
import { Link } from 'react-router-dom';

const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', flex: 1, renderCell: renderBoxLink },
  { field: 'id_instalacji', headerName: 'ID Instalacji', flex: 1 },
  { field: 'distance_meters', headerName: 'Distance (meters)', flex: 1,},
];

interface Table7Props {
  search: string;
}

const Table7: React.FC<Table7Props> = ({ search }) => {
  return <ReusableTable 
    fetchUrl="/view_box_installation_distance" 
    columns={columns} 
    search={search} 
    idColumn="id_instalacji" 
    initialSort={{ field: 'distance_meters', sort: 'desc' }}
  />;
};

export default Table7;
